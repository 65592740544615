import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import options from "../components/richtextOptions"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"

import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "100px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  titolo: {
    color: "#fe5f55",
    fontSize: "2rem",
    fontWeight: 900,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  sottotitolo: {
    color: "#c3c3c3",
    fontSize: "1.5rem",
    fontWeight: 400,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
  },
  highlight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  data: {
    color: "rgba(117, 117, 117, 1)",
    fontSize: "1rem",
    fontWeight: 200,
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    boxSizing: "inherit",
  },
  articolo: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
}))

const Manifesto = ({ data: { manifesto }, pageContext, location }) => {
  const classes = useStyles()
  const url = location.href ? location.href : ""

  return (
    <Layout
      title={manifesto.titolo}
      description={manifesto.sottotitolo}
      image={manifesto.media[0].media.fluid.src}
      isArticle={true}
      url={"/articoli/" + manifesto.slug}
      date={manifesto.data}
    >
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent} maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                <h1 className={classes.titolo}>{manifesto.titolo}</h1>
              </Grid>
              <div className={classes.highlight}>
                <div>
                  <FacebookShareButton
                    url={url}
                    hashtag="#jsdbasket"
                    quote="Guardate cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                  >
                    <FacebookIcon size={40} round={true} />
                  </FacebookShareButton>
                  <WhatsappShareButton url={url} title={manifesto.title}>
                    <WhatsappIcon size={40} round={true} />
                  </WhatsappShareButton>
                  <EmailShareButton
                    url={url}
                    subject="Guarda cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                  >
                    <EmailIcon size={40} round={true} />
                  </EmailShareButton>
                </div>
              </div>
              <Grid item xs={12}>
                <img
                  alt={manifesto.media[0].media.titolo}
                  src={manifesto.media[0].media.fluid.src}
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.articolo}>
          <Container maxWidth="md">
            <Grid container>
              <Grid item xs={12}>
                {documentToReactComponents(manifesto.testo.json, options)}
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Manifesto

export const query = graphql`
  query($slug: String!) {
    manifesto: contentfulManifesto(
      node_locale: { eq: "it-IT" }
      slug: { eq: $slug }
    ) {
      titolo
      slug
      descrizione
      media {
        titolo
        media {
          fluid(quality: 100) {
            src
          }
        }
      }
      testo {
        json
      }
    }
  }
`
